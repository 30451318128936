import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const styledDataCentersBps = css(
  mq({
    marginTop: [49.5, 50, 47, 47],
    marginBottom: [50]
  })
)

export const styledDataCenters = css`
  ${styledDataCentersBps};
`

export const H3HeaderBps = css(
  mq({
    maxWidth: [280, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    marginTop: [8],
    marginBottom: [16],
    letterSpacing: [0.25],
    textAlign: ['left'],
    fontSize: [33.6, 33.6, 33.6, 47.9],
    fontWeight: [600, 600, 600, 500]
  })
)

export const H5Bps = css(
  mq({
    fontSize: [23.7, 23.7, 33.6, 33.6],
  })
)

export const PHeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    textAlign: ['left']
  })
)

export const spanBps = css(
  mq({
    textAlign: ['left']
  })
)

export const scheduleTourBps = css(
  mq({
    marginLeft: [0],
    marginRight: [0],
  })
)

export const PTourBps = css(
  mq({
    maxWidth: [328, '100%', 328, 328]
  })
)

export const imgDc = css(
  mq({
    maxWidth: ['100%', '100%', 459, 459]
  })
)

export const lineBorderBps = css(
  mq({
    maxWidth: [328, 422, 680, 680]
  })
)

export const contactFormBps = css(
  mq({
    alignItems: ['flex-start', 'center', 'flex-start', 'flex-start']
  })
)
export const imgLarge = css(
  mq({
    height: [290, 290, '100%', '100%']
  })
)

export const imgSmall = css(
  mq({
    width: ['100%', '100%', '33.3%', '33.3%']
  })
)

export const imgMedium = css(
  mq({
    width: ['100%', '100%', '50%', '50%']
  })
)

export const imagesRow = css(
  mq({
    height: ['100%', 145, '100%', '100%']
  })
)

export const header = css`
  display: flex;
  text-align: center;
  span {
    ${spanBps};
    text-transform: uppercase;
    font-size: 11.8px;
    font-weight: 600;
    line-height: 1.36;
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 0.6);
  }
  h3 {
    ${H3HeaderBps};
    color: rgba(0, 0, 0, 0.87);
    display: grid;
  }
  p {
    ${PHeaderBps};
  }
`
export const styleEstructure = css`
  background-color: #f3f5f5;
  h5 {
    ${H5Bps};
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
  }
`

export const scheduleTour = css`
  h5 {
    color: rgba(0, 0, 0, 0.87);
    margin: 16px 0;
  }
  p {
    ${PTourBps};
  }
`
export const contactForm = css`
  ${contactFormBps};
  display: flex;
  flex-direction: column;
`
export const dcTag = css`
  background-color: #fff;
  color: #ff5800;
  padding: 12px;
  margin: 12px;
  position: absolute;
  font-weight: 400;
  font-size: 12px;
  border-radius: 4px;
  letter-spacing: 0.5px;
`